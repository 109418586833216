import Vue from 'vue'
import citiesList from "@/config/citiesList.json"
import { EagleFormConfigInterface } from '@/components/form/types/form'

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {

    }
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    let title = this.vm.$t('form_block.store.create')
    // @ts-ignore
    if (this.vm.$route.name == 'store-info') {
      title = this.vm.$t('form_block.store.update')
    }

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => title,
      defaultData: this._defaultData,
      dataAction: {
        status: false,
        back: false,
        delete: false,
        refresh: false
      },
      blocks: [
        {
          title: title,
          // help: (formInfo) => {
          //   return '主要管理員才可編輯'
          // },
          disabled: (formInfo) => {
            //@ts-ignore
            return formInfo.formData.member_id != vm.memberId
          },
          data: {
            name: {
              required: true,
              label: 'store.data.name',
              type: 'liff-text',
              disabled: (formInfo) => {
                return true
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            member_name: {
              label: 'store.data.member_name',
              type: 'liff-text',
              required: true,
              disabled: (formInfo) => {
                return true
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            phone: {
              label: 'store.data.member_phone',
              type: 'liff-text',
              required: true,
              disabled: (formInfo) => {
                return true
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            // uniform_number: {
            //   label: 'data.uniform_number',
            //   type: 'liff-text',
            //   hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
            //     if (bothEmpty(originalData, data)) return false
            //     return originalData != data
            //   },
            // },
            city: {
              label: 'data.city',
              type: 'liff-selection',
              clearable: true,
              options: () => {
                return citiesList.map(item => ({
                  text: item.city,
                  value: item.city
                }))
              },
              grid: {
                cols: 6
              },
              itemClass: "mb-2",
            hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
              if (bothEmpty(originalData, data)) return false
              return originalData != data
            },
          },
          district: {
            label: 'data.district',
            type: 'liff-selection',
            clearable: true,
            options: (formInfo) => {
              if (!formInfo.formData) return []
              const city = formInfo.formData.city
              if (!city) return []

              const targetCity = citiesList.find(item => {
                return item.city == city
              })

              if (!targetCity) return []

              return targetCity.districts.map(district => ({
                text: district.name,
                value: district.name
              }))
            },
            grid: {
              cols: 6
            },
            itemClass: "mb-2",
            hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
              if (bothEmpty(originalData, data)) return false
              return originalData != data
            },
          },
            address: {
              label: 'data-receive-address',
              type: 'liff-text',
              required: true,
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },

          },
        },
      ],
    }
  }

}

export default new formConfig()
