<template>
  <v-container v-show="loadDone">
    <eagle-form :form-key="formKey">
      <template v-slot:footer>
        <div>
          <receiving-date ref="receivingDateRef"></receiving-date>
        </div>
        <div>
          <!-- <liff-member-block
            :members="members"
            :isMainManager="isMainManager"
            :control="isMainManager"
            :autoInvite="autoInvite"
            :shareConfig="shareConfig"
            role="store"
            :providerId="providerId"
            :storeId="storeId"
            :otpApi="otpApi"
            @setOtpCode="setOtpCode"
            @setInviteRole="setInviteRole"
            @refresh="getMembers"
            class="mb-5"
          /> -->
          <v-btn
            v-if="isCancelStatus"
            @click="goProduct"
            width="100%"
            color="secondary"
            class="btn-min-width"
            >查看商品清單</v-btn
          >
        </div>
      </template>
    </eagle-form>

    <div v-if="false" class="mb-4">{{ shareConfig }}</div>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from "@/components/form/mixins/formMixin";
import formConfig from "./storeInfo";
export default {
  mixins: [formMixin],
  components: {
    // liffMemberBlock: () => import('@/modules/member/components/liffMemberBlock.vue'),
    receivingDate: () =>
      import("@/modules/store/views/receivingDate/receivingDate.vue"),
  },
  data: () => ({
    formKey: "store-info-form",
    meta: {},
    members: [],
    loadDone: false,
    otpCode: "",
    inviteRole: "",
  }),
  mounted() {
    this.getMembers();
  },
  computed: {
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`];
    },
    autoInvite() {
      return !!this.$route.query.autoInvite;
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    storeName() {
      return this.$store.getters[`member/storeName`];
    },
    providerId() {
      return this.$route.params.providerId;
    },
    memberId() {
      return this.$store.getters[`token/memberId`];
    },
    hasStore() {
      return this.$store.getters[`member/hasStore`];
    },
    store() {
      return this.$store.getters["store/store"];
    },
    cooperationStatus() {
      return this.$store.getters["store/cooperationStatus"];
    },
    // 是否為該store主要管理員
    isMainManager() {
      return this.$store.getters[`member/isStoreMainManager`];
    },
    disabledInvite() {
      if (
        this.cooperationStatus !=
        this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
      )
        return true;
      if (!this.isMainManager) return true;
      if (!this.hasStore) return true;
      return false;
    },
    storeLiffDomain() {
      return this.$store.getters[`base/storeLiffDomain`];
    },
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`];
    },
    layoutProviderLineAtId() {
      return this.$store.getters[`base/layoutProviderLineAtId`];
    },
    layoutProviderPaymentTypes() {
      return this.$store.getters[`base/layoutProviderPaymentTypes`];
    },
    otpApi() {
      const params = this.inviteRole ? { roles: [this.inviteRole] } : {};
      return () =>
        this.$api.collection.storeApi.inviteConfig(this.storeId, params);
    },
    inviteUrl() {
      return `${this.storeLiffDomain}/${this.providerId}/store/join/${this.storeId}?code=${this.otpCode}`;
    },
    shareConfig() {
      if (!this.inviteUrl) return null;
      const roleName = this.inviteRole ? this.inviteRole : "co_manager";
      return [
        {
          type: "template",
          altText: "請點此加入我的商家",
          template: {
            type: "buttons",
            title: `邀請你加入${this.storeName}訂購${this.layoutProviderName}的行列`,
            text: "請依照以下按鈕操作",
            actions: [
              {
                type: "uri",
                label: `1. 加入官方帳號`,
                uri: `https://line.me/R/ti/p/${this.layoutProviderLineAtId}`,
              },
              {
                type: "uri",
                label: `2. 成為${this.$t(roleName)}`,
                uri: this.inviteUrl,
              },
            ],
          },
        },
      ];
    },
    // 有deleted_at代表是取消合作關係
    isCancelStatus() {
      if (!this.formData) return false;
      if (!this.formData.pivot) return false;
      return !!this.formData.pivot.deleted_at;
    },
  },
  methods: {
    setInviteRole(role) {
      this.$root.gaLogEvent("商店_點擊_邀請共同管理員");
      this.inviteRole = role;
    },
    setOtpCode(code) {
      this.otpCode = code;
    },
    async getMembers() {
      this.$store.dispatch("loading/active");
      try {
        // 只抓該供應商合作下的我的商家管理員們
        let members = await this.$api.collection.storeMemberApi.storeToProviderToMangersIndex(
          this.storeId,
          this.providerId,
          { pager: 0 }
        );

        this.members = members.map((member) => {
          return {
            ...member,
            isMaster: member.IsStoreMaster,
          };
        });
      } catch (err) {
        // this.$snotify.error('讀取會員失敗')
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async copyInviteUrl() {
      try {
        await this.$copyText(this.inviteUrl);
      } catch (error) {
        console.warn(error);
      } finally {
        this.$snotify.success(this.inviteUrl, this.$t("copy.successfully"));
      }
    },
    async beforeFormInit() {
      await Promise.all([]);
    },
    validateBeforeSave() {
      // TODO
    },
    async afterSaveSuccessCallback(result) {},
    async readApi(target) {
      this.loadDone = false;
      try {
        const res = await this.$api.collection.storeApi.readByProviderAndStore(
          this.storeId,
          this.providerId
        );
        this.$refs.receivingDateRef.getReceivingDate();
        this.$store.dispatch(`store/setStore`, res);
        return res;
      } catch (err) {
        this.$store.dispatch(`store/setDefault`);
        console.error(err);
        // this.$snotify.error('我的商家不存在')
        this.$router.push({
          name: "store-register",
          params: { providerId: this.providerId },
        });
      } finally {
        this.loadDone = true;
      }
    },
    async updateApi(target, formData) {
      const params = this.$eagleLodash.cloneDeep(formData);
      delete params.pivot;
      const res = await this.$api.collection.storeApi.update(
        this.storeId,
        this.providerId,
        params
      );
      this.$refs.receivingDateRef.addReceivingDate();
      this.$store.dispatch(`store/setStore`, res);
      return res;
    },
    goProduct() {
      this.$router.push({
        name: "store-product-list",
        params: { providerId: this.providerId },
      });
    },
    getFormConfig() {
      return formConfig;
    },
  },
};
</script>

<style lang="sass" type="text/sass" scoped></style>
